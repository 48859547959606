<template>
  <div
    class="home bg-slate-200 min-h-screen p-4 max-w-[100vw]"
    :class="{ loading }"
  >
    <div class="col-top w-full">
      <h3 class="flex justify-between items-center mb-4">
        <logo-component></logo-component>
        <settings-component></settings-component>
      </h3>
      <download-component></download-component>
    </div>
    <div class="cols">
      <div class="col-1">
      </div>
      <div class="col-2">
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from '../components/LogoComponent.vue'
import SettingsComponent from '../components/SettingsComponent.vue'
import DownloadComponent from '../components/DownloadComponent.vue'
export default {
  components: { LogoComponent, SettingsComponent, DownloadComponent },
 mounted() {
    this.$axios.get('/')
 }
}
</script>

<style>

</style>