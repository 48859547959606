const start = app => {
    if (localStorage.user) {
        let auth = JSON.parse(localStorage.user);
        const monitor = new EventSource(
            `${process.env.VUE_APP_API_URL}/events/?token=${auth.credential}`
        );
        app.config.globalProperties.$monitor = monitor;
    }
}
export default {
    install: (app) => {
        app.config.globalProperties.$start_monitor = () => start(app);
    }
};