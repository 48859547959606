<template>
  <div>
    <div id="buttonDiv"></div>
  </div>
</template>
  
<script>

const API_URL = process.env.VUE_APP_API_URL

export default {
  name: "LoginComponent",
  data() {
    return {
      userDetails: null,
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
        // window.google.accounts.id.initialize({
        //   client_id: "433739171227-1iqsol7b26o2mnb5bj66k6959p2uv5rc.apps.googleusercontent.com",
        //   callback: this.handleCredentialResponse
        // });
        // window.google.accounts.id.renderButton(
        //   document.getElementById("buttonDiv"),
        //   { theme: "outline", size: "medium" }  // customization attributes
        // );
        // window.google.accounts.id.prompt(); // also display the One Tap dialog
    },
    handleCredentialResponse(response) {
        console.log(API_URL)
        console.log("Encoded JWT ID token: " + response.credential);
      }
  },
};
</script>