<template>
  <div>
    <button @click="show = !show" class="text-[12px]">
      configurações <font-awesome-icon icon="fa-solid fa-gear" />
    </button>
    <div
      v-if="show"
      class="flex fixed z-10 top-0 left-0 text-center p-4 items-center justify-center w-full h-screen bg-white"
    >
      <div class="flex flex-col items-center justify-center w-full">
        <logo-component class="scale-150 mb-4"></logo-component>
        <h3 class="mb-4">
          Insira usa chave de API da Open AI (<a
            href="https://platform.openai.com/api-keys"
            target="_blank"
            class="text-red-600"
            >https://platform.openai.com/api-keys</a
          >)
        </h3>
        <form action="" class="flex flex-col gap-4">
          <input
            v-model="$store.state.configs.OPEN_AI_API_KEY"
            class="border shadow p-4 text-center text-[12px] w-[400px] max-w-[80vw] rounded-md"
            type="text"
            placeholder="OPEN AI API KEY"
          >
          <button
            @click="save"
            class="p-4 bg-green-600 text-white flex items-center justify-center max-w-[80vw] h-[55px] rounded-md"
          >
            SALVAR
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from './LogoComponent.vue';
export default {
  components: { LogoComponent },
  name: "SettingsComponent",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    OPEN_AI_API_KEY() {
      return this.$store.state.configs.OPEN_AI_API_KEY
    },
  },
  mounted() {
  },
  methods: {
    save() {
      // this.$store.commit('setOpenAIApiKey', this.api_key)
      this.show = false;
      this.$axios.post('/sync', this.$store.state.configs)
    },
  },
};
</script>

<style>
</style>