<template>
  <div class="flex items-center justify-center bg-slate-200">
    <div
      class="flex flex-col-reverse md:flex-row md:min-h-[100vh] items-stretch w-full"
    >
      <div
        class="p-8 md:p-0 min-h-[50vh] md:min-h-screen w-full md:w-1/2 bg-white flex flex-col items-center justify-center"
      >
        <div class="flex flex-col text-center">
          <div class="hidden md:block mb-8">
            <logo-component class="scale-[200%] mb-8"></logo-component>
            <h1 class="text-[1.2em] font-normal">
              Crie e gerencie posts em sites WordPress
            </h1>
          </div>
          <iframe
            class="mt-6 player"
            src="https://www.youtube.com/embed/k1JE-3rcVD4?si=UtHJhsLtY9zaCDnU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <div
            class="m-4 flex flex-col text-[1.3em] md:flex-row gap-4 md:gap-8 items-center justify-center"
          >
            <span>1. Instale o plugin</span>
            <span>2. Conecte o site</span>
            <span>3. Crie e edite posts com IA</span>
          </div>
        </div>
      </div>
      <div
        class="min-h-[50vh] md:min-h-screen w-full md:w-1/2 flex flex-col items-center justify-center"
      >
        <div class="flex flex-col text-center">
          <h2 class="hidden text-[2em] font-bold md:block mb-2">
            Login / Cadastro
          </h2>
          <div class="md:hidden mb-8">
            <logo-component class="scale-[200%] mb-8"></logo-component>
            <h1 class="text-[1em] font-normal">
              Crie e gerencie posts em sites WordPress
            </h1>
          </div>
          <div class="m-4 flex items-center justify-center">
            <div v-if="me">
              <profile-component></profile-component>
            </div>
            <div v-else>
              <div
                id="g_id_onload"
                data-client_id="336651661679-cus5lqpirff0qffjvp5os9pqnsj0l8d6.apps.googleusercontent.com"
                data-auto_prompt="true"
              ></div>
              <div
                ref="button"
                class="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-text="continue_with"
                data-shape="rectangular"
                data-logo_alignment="left"
                data-callback="login"
              ></div>
            </div>
          </div>
          <div class="mt-8 flex justify-center gap-4 mb-8 md:mb-0">
            <router-link to="/politicas-de-privacidade"
              >Políticas de Privacidade</router-link
            ><spa>|</spa>
            <router-link to="/termos-de-uso">Termos de Uso</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import ProfileComponent from "../components/ProfileComponent.vue";
export default {
  components: { LogoComponent, ProfileComponent },
  name: "HomeView",
  computed: {
    me() {
      return this.$store.state.me;
    },
  },
  mounted() {
    if (this.me) {
    } else {
      google.accounts.id.initialize({
        client_id:
          "336651661679-cus5lqpirff0qffjvp5os9pqnsj0l8d6.apps.googleusercontent.com",
        callback: this.login,
      });
      google.accounts.id.renderButton(
        this.$refs.button,
        { theme: "outline", size: "large" } // customization attributes
      );
      google.accounts.id.prompt(); // also display the One Tap dialog
    }
  },
  methods: {
    login(token) {
      localStorage.user = JSON.stringify(token);
      this.$axios.post("/auth").then(() => {
        this.$router.push("/dashboard");
      });
    },
  },
};
</script>

<style></style>
