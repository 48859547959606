<template>
  <div class="w-full">
    <progress-component></progress-component>
    <div
      class="home bg-slate-200 min-h-screen p-4 max-w-[100vw]"
      :class="{ loading }"
    >
      <div class="col-top w-full mb-auto">
        <h3 class="flex justify-between items-center mb-4">
          <logo-component></logo-component>
        </h3>
        <profile-component class="mb-4 pr-10 md:pr-0"></profile-component>
        <credits-component></credits-component>
      </div>
      <div class="cols">
        <div
          v-if="history"
          class="p-4 rounded-md bg-white w-full flex flex-col gap-2"
        >
          <div
            class="item flex gap-2 justify-between items-center text-[14px] py-1 px-3 rounded-sm border-l-4"
            v-for="item in history.items"
            :class="{
              ['border-l-blue-400 text-black']: item.processed == 1,
              ['bg-slate-300']: item.processed == 0,
            }"
            :key="item"
          >
            <div class="text-left w-4/6">
              <div class="font-bold flex items-center gap-2">
                <a :href="getPostUrl(item)" target="_blank">
                  <span v-html="item.genereted.title"></span>
                </a>
              </div>
              <div class="flex items-center gap-2">
                <a :href="item.fonte" target="_blank">
                  <span v-html="item.title"></span>
                </a>
              </div>
            </div>
            <div
              class="text-left w-1/6 flex gap-1 text-[0.9em]"
              v-if="item.genereted.diff"
            >
              <div
                class="flex flex-col text-center p-1 border"
                v-if="item.genereted.diff.title"
              >
                <span>Título</span>
                <span
                  class="px-2 rounded-sm"
                  :class="{ [diffColor(item.genereted.diff.title)]: 1 }"
                  >{{ item.genereted.diff.title }}%</span
                >
              </div>
              <div
                class="flex flex-col text-center p-1 border"
                v-if="item.genereted.diff.content"
              >
                <span>Conteúdo</span>
                <span
                  class="px-2 rounded-sm"
                  :class="{ [diffColor(item.genereted.diff.content)]: 1 }"
                  >{{ item.genereted.diff.content }}%</span
                >
              </div>
            </div>
            <div class="text-left w-1/6">
              <div v-html="getDomain(item.site_url)"></div>
              <div v-html="item.created_at"></div>
            </div>
          </div>
          <hr class="my-2" />
          <div
            class="pagination flex gap-2 justify-center items-center text-[14px] py-1 px-3 rounded-sm"
          >
            <span
              class="flex items-center cursor-pointer justify-center w-[32px] h-[32px] text-white"
              :class="{
                ['bg-black']: page == currentPage + 1,
                ['bg-green-500']: page != currentPage + 1,
              }"
              @click="currentPage = page - 1"
              v-for="page in pages"
              :key="page"
              >{{ page }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LogoComponent from "../components/LogoComponent.vue";
import CreditsComponent from "../components/CreditsComponent.vue";
import ProfileComponent from "../components/ProfileComponent.vue";

export default {
  name: "HomeView",
  components: {
    LogoComponent,
    CreditsComponent,
    ProfileComponent,
  },
  data() {
    return {
      history: false,
      currentPage: 0,
    };
  },
  watch: {
    currentPage() {
      this.getHistory();
    },
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }
    this.getHistory();
  },
  computed: {
    pages() {
      return this.history ? Math.round(this.history.total / 25) : 0;
    },
    configs() {
      return this.$store.state.configs;
    },
    me() {
      return this.$store.state.me;
    },
    gtag() {
      return window.gtag;
    },
  },
  methods: {
    getPostUrl(item) {
      if(!item.genereted) {
        return;
      }
      if (item.genereted.response) {
        if (item.genereted.response.data) {
          return item.genereted.response.data;
        }
      }
      let site = new URL(item.site_url);
      return `${site.origin}?s=${encodeURIComponent(
        item.genereted.title
      ).replace(/%20/g, "+")}`;
    },
    diffColor(p) {
      const percent = parseFloat(p);
      if (percent < 10) {
        return "bg-red-500 text-white";
      }
      if (percent < 30) {
        return "bg-yellow-500 text-white";
      }
      if (percent < 60) {
        return "bg-blue-500 text-white";
      }
      return "bg-green-500 text-white";
    },
    getHistory() {
      this.$axios
        .get("/history", { params: { page: this.currentPage } })
        .then((res) => (this.history = res.data));
    },
    getDomain(url) {
      try {
        return new URL(url).hostname;
      } catch {
        return "----";
      }
    },
  },
};
</script>
