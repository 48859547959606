import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})



// Adicionar interceptores se necessário
apiClient.interceptors.request.use(
    config => {
        // Faça algo antes da requisição ser enviada, como adicionar tokens de autenticação
        let user = localStorage.user ? JSON.parse(localStorage.user) : false;
        if (user && user.credential) {
            config.headers.Authorization = `Bearer ${user.credential}`
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    response => {
        // Faça algo com os dados da resposta
        return response;
    },
    error => {
        // Faça algo com o erro da resposta
        return Promise.reject(error);
    }
);

export default {
    install: (app) => {
        app.config.globalProperties.$axios = apiClient;
    }
};