<template>
  <div>
    <VOnboardingWrapper ref="wrapper" @finish="finished" :steps="steps" :options="options" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { VOnboardingWrapper, useVOnboarding } from "v-onboarding";
import { version } from "../../package";
import "v-onboarding/dist/style.css";
export default defineComponent({
  components: {
    VOnboardingWrapper,
  },
  setup() {
    const wrapper = ref(null);
    const { start, goToStep, finish } = useVOnboarding(wrapper);
    const steps = [
      {
        attachTo: { element: "#sync" },
        content: { title: "Sincronize seus dados entre os dispositivos!" },
      },
      {
        attachTo: { element: "#history" },
        content: { title: "Veja um historico de postagens!" },
      },
      {
        attachTo: { element: "#show_search" },
        content: { title: "Cadastre fontes de referência para criar seus posts!" },
      },
      {
        attachTo: { element: "#caculate" },
        content: { title: "Veja o valor do post que será debitado do seu crédito!" },
      },
      {
        attachTo: { element: "#original-post" },
        content: { title: "Marque essa caixa para não gerar um novo texto com IA!" },
      },
      {
        attachTo: { element: "#update-post" },
        content: { title: "Atualize posts existentes" },
      },
    ];

    const options = {
      popper: {},
      overlay: {
        enabled: true,
        padding: 0,
        borderRadius: 0,
        preventOverlayInteraction: true,
      },
      scrollToStep: {
        enabled: true,
        options: {
          behavior: "smooth",
          block: "center",
          inline: "center",
        },
      },
      autoFinishByExit: true,
      hideButtons: {
        previous: false,
        next: false,
        exit: false,
      },
      labels: {
        previousButton: "Anterior",
        nextButton: "Próximo",
        finishButton: "Terminar",
      },
      hideNextStepDuringHook: false,
    };

    if(!localStorage[`tour-${version}`]) {
        onMounted(() => setTimeout(start, 2000));
    }

    const finished = () => localStorage[`tour-${version}`] = 1

    return {
      wrapper,
      steps,
      options,
      finished,
    };
  },
});
</script>
