<template>
  <p class="instructions">
          Instale o plugin
          <a class="download" href="/iaxpress.zip">IAXpress</a> no site
          para fazer a conexão.
        </p>
</template>

<script>
export default {

}
</script>

<style>

</style>