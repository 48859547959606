import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const rss = (app) => {

    let u = new URL(app.fontePosts);
    let url = app.fontePosts;
    const id = app.$toast.loading("Buscando posts...", {
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
    });

    app.$axios
        .post(`${app.proxyUrl}/rss`, { url })
        .then((res) => {
            app.posts = res.data;
            app.$toast.update(id, {
                render: res.data.length
                    ? `Posts carregados`
                    : "Nenhum post encontrado",
                autoClose: true,
                closeOnClick: true,
                closeButton: true,
                type: res.data.length ? "success" : "error",
                isLoading: false,
            });
        })
}

export default {
    install: (app) => {
        app.config.globalProperties.$rssSearch = app => rss(app);
    }
};