import { createRouter, createWebHashHistory } from 'vue-router'
import Dashboard from '../views/DashboardView.vue'
import HomeView from '@/views/HomeView.vue'
import AccountView from '@/views/AccountView.vue'
import TermosView from '@/views/TermosView.vue'
import PoliticasView from '@/views/PoliticasView.vue'
import HistoryView from '@/views/HistoryView.vue'
import IntegrateView from '@/views/IntegrateView.vue'

const routes = [
  {
    path: '/termos-de-uso',
    name: 'termos',
    component: TermosView
  },
  {
    path: '/politicas-de-privacidade',
    name: 'politicas',
    component: PoliticasView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/:token',
    name: 'integrate',
    component: IntegrateView
  },
]

const router = createRouter({
  mode: 'hash',
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
