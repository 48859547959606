<template>
  <div v-if="items" @click="$router.push('/history')" class="bg-yellow-400 p-2 text-center text-black">{{ items }} items sendo processados</div>
</template>

<script>
export default {
  data() {
    return {
      items: 0,
    };
  },
  mounted() {
    this.$monitor?.addEventListener('processing', (e) => {
      this.items = parseInt(e.data)      
    })
  },
};
</script>

<style></style>
