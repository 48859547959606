<template>
  <router-view />
</template>
<script>
export default {
  watch: {
    $route: function () {
      if (localStorage.user) {
        if(!this.$monitor) {
          this.$start_monitor()
        }
        this.$axios
          .get("/me")
          .then((res) => {
            this.$store.commit("setMe", res.data);
            this.$axios
              .get("/sync")
              .then((res) => this.$store.commit("setConfigs", res.data || {}));
          })
          .catch(() => {
            delete localStorage.user;
            location.reload();
          });
      }
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
  mounted() {    
    window.addEventListener(
      "resize",
      () => (this.$store.commit('windowWidth', window.innerWidth))
    );
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
}
</style>
