<template>
  <div class="w-full">
  </div>
</template>

<script>
// @ is an alias to /src
import { version } from "../../package";
export default {
  name: "IntegrateView",
  data() {
    return {
      version,
      sites: {},
      currentSite: false,
    };
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }
    this.loadData().then(() => {
      if (this.$route.params.token) {
        let site = btoa(localStorage.connecting);
        if (this.sites[localStorage.connecting]) {
          let token = this.$route.params.token.split(":")[0];
          let token_key = this.$route.params.token.split(":")[1];
          this.$axios.post(`update-site-token/${site}`, {token, token_key})
        } else {
          this.$axios.post(`add-site/${site}`, {
            counter_total: 0,
            active: true,
            token: this.$route.params.token.split(":")[0],
            token_key: this.$route.params.token.split(":")[1],
            categories: [],
            selected_categories: [],
            form: {
              caption: null,
              image: null,
              title: null,
              content: null,
              categories: [],
              tags: [],
            },
            counter: {},
          })
        }
        setTimeout(() => {
          delete localStorage.connecting;
          setTimeout(() => {
            location.href = "/#/dashboard";
          }, 1000);
        }, 1000);
      }
    });
  },
  methods: {
    loadData() {
      return this.$axios
        .get("/sync")
        .then((res) => {
          for (let prop in res.data) {
            if (prop == "version") continue;
            this[prop] = res.data[prop];
          }
          this.loading = false;
        })
    },
  },
};
</script>
